import React from 'react'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { Link } from '@kogk/gatsby-theme-base'
import styles from './SubscriptionTiersWidget.module.scss'
import cn from 'classnames'
import { Container, Row, Col } from '@kogk/common'

export default ({ tiers, standalone }) => {
  const { t } = useLanguage()
  return (
    <Container
      fluid
      className='p-0 my-4 d-flex justify-content-center h-100 align-items-center'
    >
      <Row>
        <Col col={{ lg: 12 }}>
            <div className={styles.cardContainer}>
              {tiers.map((tier, key) => {
                return (
                  <div key={key} className={styles.tierCard}>
                    <span className={cn(styles.tierTitle, {
                      [styles.tierTitleBlue]: tier.color === 'blue',
                      [styles.tierTitleYellow]: tier.color === 'yellow',
                      [styles.tierTitleGreen]: tier.color === 'green',
                      [styles.tierTitleBlack]: tier.color === 'black',
                    })}>{tier.title}</span>
                      <div className={cn(styles.tierBenefits, {
                        [styles.tierBenefitsBlue]: tier.color === 'blue',
                        [styles.tierBenefitsYellow]: tier.color === 'yellow',
                        [styles.tierBenefitsGreen]: tier.color === 'green',
                        [styles.tierBenefitsBlack]: tier.color === 'black',
                      })} dangerouslySetInnerHTML={{ __html: tier.benefits.html }} />
                      <div className={cn(styles.tierAmountContainer, {
                        [styles.tierAmountBlue]: tier.color === 'blue',
                        [styles.tierAmountYellow]: tier.color === 'yellow',
                        [styles.tierAmountGreen]: tier.color === 'green',
                        [styles.tierAmountBlack]: tier.color === 'black',
                      })}>
                        <span className={styles.tierAmount}>{tier.amount.toLocaleString('de')} KR.</span>
                        <span className={styles.tierSubtext}>{t(`styrktarverkefni|minimum amount`)}</span>
                    </div>
                      {tier.link && <Link
                        to={`${tier.link.url}?amount=${tier.amount}&tier=${tier.name}`}
                        className={cn(
                          styles.tierButton,
                          {
                            [styles.tierButtonBlue]: tier.color === 'blue',
                            [styles.tierButtonYellow]: tier.color === 'yellow',
                            [styles.tierButtonGreen]: tier.color === 'green',
                            [styles.tierButtonBlack]: tier.color === 'black',
                          }
                        )}>
                          {t(`styrktarverkefni|buy`)}
                        </Link>}
                  </div>
                  )})}
              </div>
          </Col>
      </Row>
  </Container>)
}
